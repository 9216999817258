import React from "react"
import Grid from "../../components/gridLayout"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/tools.scss"

const toolList =[{
    link:'/tools/valuation',
    desc:'Find out how much you could sell your business for.',
    title:'Valuation Tool'
},{
  link:'/tools/mrr-calculator',
  desc:'See how fast your Monthly Recurring Revenue (MRR) could grow.',
  title:'MRR Calculator'
},
// {
//   link:'/tools/funnel-profitability',
//   desc:'Understand and see the profitability of each and every step of your funnel.',
//   title:'Funnel Conversion Rate/Profitability Tool'
// }
]

export default () => (
  <Layout>
      <SEO
        title="Tools"
        description="Every tool you need for entrepreneurship"
      />
      <h1 style={{textAlign:'center'}}>Tools</h1>
      <Grid items={toolList} />
  </Layout>
)

